// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
	production: false,
	defaultLocationAddress: 'Fairfield, CT',
	defaultLocationLat: '41.1408363',
	defaultLocationLng: '-73.26126149999999',
	// stripe_client_id: 'ca_AAhA8boB1x100ZQb3yZChrwyO8CDHv5b',
	stripe_client_id: 'ca_F4XIPAExOZWq3IPNXWRzX7W4lCgCEquM',

	private_key: 'PI@!2N#DO',
	public_key: 'PIN2#D@!O',

	//DEV UIPL DETAILS
	// baseUrl: 'http://dev.uiplonline.com/pindo-server/api',
	// chatUrl: 'http://dev.uiplonline.com',
	// communityUrl: 'http://dev.uiplonline.com:4046'

	//DEV PINDO DETAILs
	// baseUrl: 'http://dev.pindoit.com/pindo-server/api',
	// chatUrl: 'http://dev.pindoit.com',
	// communityUrl: 'http://dev.pindoit.com:4049'


	//Beta PINDO DETAILs
	baseUrl: 'https://beta.pindoit.com/pindo-server/api',
	chatUrl: 'https://beta.pindoit.com',
	communityUrl: 'https://beta.pindoit.com:4046'

	// baseUrl: 'http://beta.pindoit.com/pindo-server/api',
	// chatUrl: 'http://beta.pindoit.com',
	// communityUrl: 'http://beta.pindoit.com:4046'

	//GLOBAL PINDO DETAILs
	// baseUrl: window.location.protocol + '//' + window.location.hostname + ':8000/api',
	// chatUrl: window.location.protocol + '//' + window.location.hostname,
	// communityUrl: window.location.protocol + '//' + window.location.hostname + ':4047',

	// baseUrl: 'https://pindoit.com/pindo-server/api',
	// chatUrl: 'https://pindoit.com',
	// communityUrl: 'https://pindoit.com:4047' 

	// baseUrl: 'http://192.168.1.239/pindo-server/api',
	// chatUrl: 'http://192.168.1.239',
	// communityUrl: 'http://192.168.1.239:4046'

	//LOCAL PINDO DETAILS
	/*baseUrl: window.location.protocol + '//' + window.location.hostname + '/pindo-server/api',
	chatUrl: window.location.protocol + '//' + window.location.hostname,
	communityUrl: window.location.protocol + '//' + window.location.hostname + ':4046',*/

	// HOME PINDO DETAILs
	// baseUrl: 'http://localhost/uipl/pindo-server/api',
	// chatUrl: 'http://dev.pindoit.com',
	// communityUrl: 'http://dev.pindoit.com:4049'

};

