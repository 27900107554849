import { Component, OnInit, Input, ViewChild, AfterViewInit, Renderer2, ElementRef, ChangeDetectorRef, Output, EventEmitter } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material';
import { CommonService } from '../../../../commonservice';
import { Globalconstant } from '../../../../global_constant';
import { Router } from '@angular/router';
import * as CryptoJS from 'crypto-js';
import Swal from 'sweetalert2';

@Component({
  selector: 'contact-list-dialog',
  templateUrl: 'contact-list-dialog.html',
  styleUrls: ['./contact-list-dialog.component.scss']
})
export class ContactListDialog {
  friendrequest_list: any = [];
  total_numberod_request: number = 0;
  number_flag_count: number = 0;
  show_see_all: boolean = true;
  user_type: any;
  loginUserId: any;
  loginUserName: any;
  request_response: any = [];
  offset: any = 0;
  limit: any = 3;
  type_of_page: any;
  image_url: any;
  companylogo_url: any;

  constructor(
    public dialog: MatDialog,
    public commonservice: CommonService,
    public renderer: Renderer2,
    public el: ElementRef,
    public ref: ChangeDetectorRef,
    public snackBar: MatSnackBar,
    public myGlobals: Globalconstant,
    public router: Router) {
    this.user_type = parseInt(atob(localStorage.getItem('user_type')));
    this.loginUserId = window.atob(localStorage.getItem('frontend_user_id'));
    this.loginUserName = localStorage.getItem('name');
  }

  ngOnInit() {
    this.getSendFriendRequest(this.offset, this.limit, this.type_of_page);
    this.companylogo_url = this.myGlobals.uploadUrl + '/company_logo/';
    this.image_url = this.myGlobals.uploadUrl + '/profile_photo/';
  }

  /**
 * Opens user details
 * @param user_id
 * @param user_type
 */
  openUserDetails(user_id: number, user_type: number) {
    let b64 = CryptoJS.AES.encrypt(`${user_id}`, 'Secret Key').toString();
    let e64 = CryptoJS.enc.Base64.parse(b64);
    let eHex = e64.toString(CryptoJS.enc.Hex);
    if (user_type == 1) {
      // this.router.navigate([]).then(result => { window.open(`public/pinner-profile/${eHex}`, '_blank'); });
      this.router.navigate([`public/pinner-profile/${eHex}`]);
    } else {
      // this.router.navigate([]).then(result => { window.open(`doer/doer-profile/${eHex}`, '_blank'); });
      this.router.navigate([`doer/doer-profile/${eHex}`]);
    }
  }

  /**
 * Changes friend request status (Accecpt or declined)
    * @param id (request id)
    * @param user_id (user id)
    * @param status (accecpt or declined status (if 0 it is declined , if 1 so it accecpted))
    */
  acceptOrDeclineFriendRequest(id, user_id, user_type, status) {
    if (status == 2) {
      Swal({
        title: 'Are you sure you want to decline this invitation to connect?',
        text: '',
        //type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#bad141',
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
      }).then((resultswal) => {
        if (resultswal.value) {
          this.acceptOrDeclineFriendRequestApi(id, user_id, user_type, status);
        }
      });
    } else {
      this.acceptOrDeclineFriendRequestApi(id, user_id, user_type, status);
    }
  }


  /**
  * Accepts or decline friend request api
  * @param id
  * @param user_id
  * @param status
  */
  acceptOrDeclineFriendRequestApi(id, user_id, user_type, status) {
    let link = user_type == 1 ? 'community/community-contacts' : 'doer/community-contacts';
    let msg = status == 2 ? localStorage.getItem('name') + ' has declined your connection request.' : localStorage.getItem('name') + ' swiped right! Your invite to connect was accepted.';

    this.commonservice.putCommunityHttpCall(
      {
        url: '/api/pinner/respond-connection-request',
        data: { id: id, is_approved: status },
        contenttype: 'application/json'
      })
      .then(result => {
        this.request_response = result.data;
        if (result.status == 1) {
          if (status == 1) {
            var postData = {
              'sender_id': this.loginUserId,
              'reciver_id': user_id,
              'post_id': '',
              'title': msg,
              'link': link,
              'show_in_todo': 0,
            };
            this.myGlobals.notificationSocket.emit('post-community-notification', postData);
          }
          this.responseMessageSnackBar(result.msg);
          this.getSendFriendRequest(this.offset, this.limit, this.type_of_page);
        }
      });
  }

  /**
   * Gets the list of the friend request
   * @param offsetp
   * @param limitp
   * @param type_of_page
   */
  getSendFriendRequest(offsetp, limitp, type_of_page) {
    this.commonservice.postCommunityHttpCall({
      url: '/api/pinner/list-connection-request',
      data: { offset: offsetp, limit: limitp },
      contenttype: 'application/json'
    }).then(result => {
      if (result.status == 1) {
        this.friendrequest_list = result.data.rows;
        this.total_numberod_request = result.number_of_requests[0];
        if (type_of_page == 'back') {
          this.number_flag_count = this.number_flag_count - this.friendrequest_list.length;
        } else {
          this.number_flag_count = this.number_flag_count + this.friendrequest_list.length;
        }
        if (this.number_flag_count == this.total_numberod_request) {
          this.show_see_all = false;
        } else {
          this.show_see_all = true;
        }
      }
      if (result.status == 0) {
        this.friendrequest_list = [];
      }
    });
  }

  /**
   * Responses message snack bar for notification into right side below
   * @param message
   * @param [res_class]
   */
  public responseMessageSnackBar(message, res_class = '') {
    this.snackBar.open(message, '', {
      duration: 4000,
      horizontalPosition: 'right',
      panelClass: res_class
    });
  }

}
