import { Component } from '@angular/core';

@Component({
	selector: 'app-root',
	template: `<app-layout></app-layout>`,
	styleUrls: ['./app.component.css']
})

export class AppComponent {

	title1 = 'app12';
	islogin: any;
	user_type: any;
	public success_msg = '';
	public reg_class = '';
	public errorMsg = '';
	public CHAT_URL = '';


	constructor() {
		this.islogin = 0;
		//DEV UIPL DETAILS
		// this.CHAT_URL = 'http://dev.uiplonline.com:3009';

		//DEV PINDO DETAILS
		// this.CHAT_URL = 'http://dev.pindoit.com:3010/';
		// this.CHAT_URL = 'http://dev.pindoit.com:3008/';

		//LOCAL PINDO DETAILS
		// this.CHAT_URL = window.location.protocol+'//'+window.location.hostname+':3009';

		// BETA PINDO
		this.CHAT_URL = window.location.protocol + '//' + window.location.hostname + ':3008';
		// this.CHAT_URL = 'https://beta.pindoit.com:3008';
		//PRODUCTION PINDO
		// this.CHAT_URL = window.location.protocol + '//' + window.location.hostname + ':3009';

		// this.CHAT_URL = 'http://pindoit.com:3009/';
		// this.CHAT_URL = 'https://pindoit.com:3009';

		// OTHERS
		// this.CHAT_URL = 'http://192.168.1.88:3009';


	}
}
